import React from "react";
import { NavLink } from "react-router-dom";
import strings from "../Language";
import { disconnect } from '@wagmi/core'
import swal from "sweetalert";


class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lan_: localStorage.getItem("language"),
            language_image: localStorage.getItem("language_image"),
            menu_show: false,
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
    }

    changeLanguage(lang, language_image) {
        var self = this;
        if (lang) {
            strings.setLanguage(lang);
            localStorage.setItem("language", lang);
            localStorage.setItem("language_image", language_image);
            window.location.reload()
        }
    }

    /**
     * Change Language
     * @param {string} obj 
     */
    handleChange = async (event) => {
        let obj = event.target.value;
        if (obj === "th") {
            strings.setLanguage('th');
            await localStorage.setItem("language", 'th');
            this.setState({ lang: 'th' });
            window.location.reload();
        }
        else {
            strings.setLanguage('en');
            await localStorage.setItem("language", 'en');
            this.setState({ lang: 'en' });
            window.location.reload();
        }
    };

    async accountDisconnect() {
        swal({
            title: strings["Are you sure?"],
            text: strings["Are you sure you want to disconnect?"],
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((data) => {
            if (data) {
                disconnect();
                localStorage.clear();
                window.location.replace('/');
                this.setState({ logout_confirm: false });
            }
        });
    }

    Logout() {
        swal({
            title: strings["Are you sure?"],
            text: strings["Are you sure you want to logout?"],
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((data) => {
            if (data) {
                localStorage.clear();
                window.location.replace('/');
                this.setState({ logout_confirm: false });
            }
        });
    }

    render() {
        return (
            <>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                    <nav className="navbar navbar-expand-lg">
                        <div className="container-fluid">
                            <div className="d-flex">
                                <div className={"dropdown " + (this.state.menu_show ? 'show' : '')}
                                    type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                    onClick={() => this.setState({ menu_show: !this.state.menu_show })}>
                                    <img src={this.state.language_image ? this.state.language_image : 'assets/images/flag.png'} alt="" />
                                    <a href="#" className="dropdown-toggle">{this.state.lan_}</a>
                                    <ul className={"dropdown-menu " + (this.state.menu_show ? 'show' : '')}>
                                        <li><a onClick={() => this.changeLanguage("EN", "assets/images/flag.png")} className="dropdown-item" href="#"><img src="assets/images/flag.png" alt="" /> EN</a></li>
                                        <li><a onClick={() => this.changeLanguage("CN", "assets/images/cn.png")} className="dropdown-item" href="#"><img src="assets/images/cn.png" alt="" /> CN</a></li>
                                        <li><a onClick={() => this.changeLanguage("KR", "assets/images/kr.png")} className="dropdown-item" href="#"><img src="assets/images/kr.png" alt="" /> KR</a></li>
                                        <li><a onClick={() => this.changeLanguage("VN", "assets/images/vn.png")} className="dropdown-item" href="#"><img src="assets/images/vn.png" alt="" /> VN</a></li>
                                        <li><a onClick={() => this.changeLanguage("IN", "assets/images/in.png")} className="dropdown-item" href="#"><img src="assets/images/in.png" alt="" /> IN</a></li>
                                        <li><a onClick={() => this.changeLanguage("ES", "assets/images/es.png")} className="dropdown-item" href="#"><img src="assets/images/es.png" alt="" /> ES</a></li>
                                        <li><a onClick={() => this.changeLanguage("CZ", "assets/images/cz.png")} className="dropdown-item" href="#"><img src="assets/images/cz.png" alt="" /> CZ</a></li>
                                        <li><a onClick={() => this.changeLanguage("PT", "assets/images/pt.png")} className="dropdown-item" href="#"><img src="assets/images/pt.png" alt="" /> PT</a></li>
                                        <li><a onClick={() => this.changeLanguage("RU", "assets/images/ru.png")} className="dropdown-item" href="#"><img src="assets/images/ru.png" alt="" /> RU</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>

                    <div>
                        <button onClick={() => this.accountDisconnect()} type="button" className="btn btn-danger rounded-pill" style={{ marginRight: 10 }}>{strings["Disconnect"]}</button>
                    </div>

                    {/* <div>
                        <button onClick={() => this.Logout()} type="button" className="btn btn-danger rounded-pill" style={{ marginRight: 10 }}>{strings["Logout"]}</button>
                    </div> */}
                </div>
            </>
        );
    }
}

export default Header;