import React from 'react';
import { Link, json } from 'react-router-dom'
import strings from '../../components/Language';
import { Helmet } from 'react-helmet';
import Slider from "react-slick";
import { api } from '../../components/config/api';
import { USDT_ADDRESS, NODE_ABI, NODE_ADDRESS, FUT_ADDRESS } from '../../components/config/contract';
import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment/moment';
import { getToken, getUserData, number_format, storeUserData } from '../../components/Util';

import Header from '../../components/Layout/Header';
import SideBarWeb from '../../components/Layout/SideBarWeb';
import SideBarMobile from '../../components/Layout/SideBarMobile';
import LatestNews from '../../components/Layout/LatestNews';

import Modal from 'react-modal';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Button, Web3Modal } from '@web3modal/react'
import { configureChains, createConfig} from 'wagmi'
import { bscTestnet } from 'wagmi/chains'
import { writeContract, erc20ABI, getAccount, watchWalletClient, watchAccount, readContract, fetchBalance } from '@wagmi/core'
import swal from 'sweetalert';
const bsc = { "id": 663, "name": "Futura Smart Chain", "network": "fsc", "nativeCurrency": { "decimals": 18, "name": "FSC", "symbol": "FSC" }, "rpcUrls": { "default": { "http": ["https://rpc.fscscan.com"] }, "public": { "http": ["https://rpc.fscscan.com"] } }, "blockExplorers": { "etherscan": { "name": "FSCScan", "url": "https://fscscan.com" }, "default": { "name": "FscScan", "url": "https://fscscan.com" } }, "contracts": { "multicall3": { "address": "0xca11bde05977b3631167028862be2a173976ca11", "blockCreated": 15921452 } } };
const chains = [bsc]
const projectId = '0efa69b6baac1854702c33c9953af8df';
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiConfig = createConfig({
	autoConnect: true,
	connectors: w3mConnectors({ projectId, chains }),
	publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains)

class NodesScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			lang: localStorage.getItem("language"),
			isLoading: false,
			is_modal: false,
			access_token: "",
			user_data: {},
			credit_data: {},
			data: [],
			deduct_from: "Pocket",
			amount: 0,
			errorMessage: [],

			is_connected: false,
			usdt_balance: 0,
			fud_balance: 0,
			is_usdt_approved: 0,
			is_fud_approved: 0,

		}
	}

	async componentDidMount() {
		let self = this;
		window.scrollTo(0, 0)
		var token = await getToken();
		if (token) {
			await storeUserData(token);
			var user_data = getUserData();
			self.setState({
				access_token: token,
				user_data: (user_data && user_data.user) ? user_data.user : {},
				credit_data: (user_data && user_data.credit) ? user_data.credit : '',
			});
			setTimeout(() => {
				self.getData(api.node_purchase)
			});
		}

		watchAccount((account) => {
			if (account != null || account != undefined) {
				if (account.isConnected) {
					this.setState({ is_connected: true, account: account.address });
					this.balanceUSDT(account.address);
					this.balanceFUD(account.address);
					this.checkIsApprovedUSDT(account.address);
					this.checkIsApprovedFUD(account.address);
				}
				else {
					this.setState({ is_connected: false, account: account.address })
				}
			}
		});
	}

	getData(API) {
		if (API) { } else { return false; }
		var self = this;
		axios({
			method: 'GET',
			url: API,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {
			if (ress.data && ress.data.error === 200) {
				self.setState({ data: (ress.data.data && ress.data.data.packages) ? ress.data.data.packages : [] });
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
		}).catch(function (e) {
			//toast.error(e.message);
		});
	}

	isModalOpen(package_id) {
		this.setState({
			package_id: package_id,
			is_modal: !this.state.is_modal
		})
	}

	packagePurchaseConfirm(package_id) {
		swal({
			title: strings["Confirm Transaction"],
			text: strings["Confirm node purchase?"],
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((data) => {
			if (data) {
				this.packagePurchase(package_id);
			}
		});
	}

	packagePurchase(package_id) {
		var self = this;
		if (!package_id) {
			toast.error("Package id not found!");
			return false;
		}
		self.setState({ isLoading: true });
		var json = {
			package_id: package_id,
		}
		axios({
			method: 'POST',
			url: api.node_purchase,
			data: json,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {
			if (ress.data && ress.data.error === 200) {
				toast.success(ress.data.message);
				self.getData(api.node_purchase);
				self.setState({ is_modal: !self.state.is_modal, deduct_from: "Wallet" });
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
			self.setState({ isLoading: false });
		}).catch(function (e) {
			self.setState({ isLoading: false });
			//toast.error(e.message);
		});
	}

	async checkIsApprovedUSDT(address) {
		const data = await readContract({
			address: USDT_ADDRESS,
			abi: erc20ABI,
			functionName: 'allowance',
			args: [
				address,
				NODE_ADDRESS,
			],
		})
		if (data != 0n) {
			this.setState({ is_usdt_approved: true });
		}
	}

	async checkIsApprovedFUD(address) {
		const data = await readContract({
			address: FUT_ADDRESS,
			abi: erc20ABI,
			functionName: 'allowance',
			args: [
				address,
				NODE_ADDRESS,
			],
		})
		if (data != 0n) {
			this.setState({ is_fud_approved: true });
		}
	}

	async balanceUSDT(address) {
		const balance = await fetchBalance({
			address: address,
			token: USDT_ADDRESS,
			formatUnits: 'gwei',
		})
		this.setState({ usdt_balance: balance.formatted });
	}

	async balanceFUD(address) {
		const balance = await fetchBalance({
			address: address,
			token: FUT_ADDRESS,
			formatUnits: 'gwei',
		})
		this.setState({ fud_balance: balance.formatted });
	}

	async doApproveUSDT() {
		try {
			this.setState({ onpress: true })
			this.setState({ isLoading: true })
			const receipt = await writeContract({
				address: USDT_ADDRESS,
				abi: erc20ABI,
				functionName: 'approve',
				args: [
					NODE_ADDRESS,
					"10000000000000000000000000"
				],
			})
			if (receipt) {
				toast.success("Approve Completed")
			}
			this.setState({ onpress: false })
			this.setState({ isLoading: false })
		}
		catch (e) {
			this.setState({ onpress: false })
			this.setState({ isLoading: false })
			toast.error("Approve Failed")
		}
	}

	async doApproveFUD() {
		try {
			this.setState({ onpress: true })
			this.setState({ isLoading: true })
			const receipt = await writeContract({
				address: FUT_ADDRESS,
				abi: erc20ABI,
				functionName: 'approve',
				args: [
					NODE_ADDRESS,
					"10000000000000000000000000"
				],
			})
			if (receipt) {
				toast.success("Approve Completed")
			}
			this.setState({ isLoading: false })
			this.setState({ onpress: false })
		}
		catch (e) {
			this.setState({ onpress: false })
			this.setState({ isLoading: false })
			toast.error("Approve Failed")
		}
	}

	async doPurchase() {
		try {
			this.setState({ onpress: true })
			this.setState({ isLoading: true })
			const receipt = await writeContract({
				address: NODE_ADDRESS,
				abi: NODE_ABI,
				functionName: 'buy',
				args: [
				],
			})
			if (receipt) {
				toast.success("Purchased Completed");
				this.addNode();
			}
			this.setState({ onpress: false })
			this.setState({ isLoading: false })
		}
		catch (e) {
			this.setState({ onpress: false })
			this.setState({ isLoading: false })
			toast.error("Approve Failed")
		}
	}

	addNode() {
		var self = this;
		self.setState({ isLoading: true });
		axios({
			method: 'POST',
			url: api.node_add,
			data: json,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {
			self.setState({ isLoading: false });
		}).catch(function (e) {
			self.setState({ isLoading: false });
		});
	}
	render() {

		const settings = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: (this.state.data && this.state.data.length < 3) ? this.state.data.length : 3,
			slidesToScroll: 1
		};

		return (
			<>
				<Helmet><title>{"FUTURA | Nodes"}</title></Helmet>
				<div className="dashboard">
					<Header />

					<div className="container-fluid">
						<div className="row">
							<SideBarWeb />

							<>
								<div className="col content">
									<br />
									<h1>{strings["Hello"]}!<br /> {(this.state.user_data && this.state.user_data.username) ? this.state.user_data.username : '-'}</h1>
									<div className="panel nodes">
										{/* <div className="owl-carousel owl-theme node"> */}
										{this.state.data && this.state.data.length != 0 ?
											<Slider {...settings}>
												{this.state.data.map((item, i) =>
													<div className="item" key={i * 234}>
														<div className="found">
															<div className="shade">
																<h1>{item.name}</h1>
																<div className="hilite">
																	<div className="row gx-3">
																		<div className="col-3">
																			{item.usdt}
																		</div>
																		<div className="col-3">
																			{strings["USDT"]}
																		</div>
																		<div className="col-6">
																			{item.fud} {strings["FUT"]}
																		</div>
																	</div>
																</div>
																<div className="hilite">
																	<div className="row gx-3">
																		<div className="col-3">
																			{item.vault}
																		</div>
																		<div className="col-3">
																			{strings["FUT"]}
																		</div>
																		<div className="col-6">
																			{strings["IN VAULT"]}
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div className="purchase" onClick={() => this.isModalOpen(item.id)} style={{ cursor: "pointer" }}>
															<div className="shade">
																<h1>{strings["PURCHASE NOW"]}</h1>
																{/* <h1>PURCHASE NOW <i className="fa fa-lock"></i></h1> */}
															</div>
														</div>
													</div>
												)}
											</Slider>
											: null}
										{/* </div> */}
									</div>
								</div>
							</>

							<LatestNews />

							<Modal
								isOpen={this.state.is_modal}
								style={{ content: { background: "transparent" } }}
							>
								<div className="modal-content modal-simple">
									<div className="modal-header">
										<h3>{strings["Pocket Balance"]}</h3>
									</div>
									<div className="modal-body">

										<div className="mb-3">
											<div className="form-control radio" style={{ background: "#2d3e5c" }}>
												<label style={{ display: "flex", justifyContent: 'space-between', alignItems: "center" }}>
													{strings["Deduct From Pocket"]}
													<input onClick={() => this.setState({ deduct_from: "Pocket" })} type="radio" name='deduct_from' value="Pocket" checked={(this.state.deduct_from == "Pocket") ? true : false} />
												</label>
											</div>
										</div>

										<div className="mb-3">
											<div className="form-control radio" style={{ background: "#2d3e5c" }}>
												<label style={{ display: "flex", justifyContent: 'space-between', alignItems: "center" }}>
													{strings["Deduct From Wallet"]}
													<input onClick={() => this.setState({ deduct_from: "Wallet" })} type="radio" name='deduct_from' value="Wallet" checked={(this.state.deduct_from == "Wallet") ? true : false} />
												</label>
											</div>
										</div>
										<div className='mb-3'> {this.state.errorMessage.deduct_from && <div className='errorMessage'> {this.state.errorMessage.deduct_from[0]} </div>}</div>
										{
											this.state.deduct_from == "Pocket" ?
												<>
													<div className="balance">
														<div className="row mb-2">
															<div className="col-auto">
																{strings["Pocket Balance"]}:
															</div>
														</div>
														<div className="row">
															<div className="col-auto">
																{number_format((this.state.credit_data && this.state.credit_data.usdt) ? this.state.credit_data.usdt : 0, 4, '.', ',')}
															</div>
															<div className="col-auto">
																{strings["USDT"]}
															</div>
														</div>
														<div className="row">
															<div className="col-auto">
																{number_format((this.state.credit_data && this.state.credit_data.fud) ? this.state.credit_data.fud : 0, 4, '.', ',')}
															</div>
															<div className="col-auto">
																{strings["FUT"]}
															</div>
														</div>
													</div>
												</>
												:
												<div className="balance">
													<div className="row mb-2">
														<div className="col-auto">
															{strings["Wallet Balance"]}:
														</div>
													</div>
													<div className="row">
														<div className="col-auto">
															{number_format(this.state.usdt_balance, 4, '.', ',')}
														</div>
														<div className="col-auto">
															{strings["USDT"]}
														</div>
													</div>
													<div className="row">
														<div className="col-auto">
															{number_format(this.state.fud_balance, 4, '.', ',')}
														</div>
														<div className="col-auto">
															{strings["FUT"]}
														</div>
													</div>
												</div>
										}

									</div>
									{
										this.state.deduct_from == "Pocket" ?
											<>
												<div className="modal-footer">
													<button onClick={() => this.setState({ is_modal: !this.state.is_modal })} type="button" className="btn btn-warning rounded-pill" style={{ background: "#DE9E5E", marginRight: 10 }}>{strings["Cancel"]}</button>
													{this.state.isLoading ?
														<div type="button" className="btn btn-primary loader-btn rounded-pill">
															<div class="spinner-border loader-spinner" role="status">
																<span class="visually-hidden">{strings["Loading"]}...</span>
															</div>
															{strings["Loading"]}
														</div>
														:
														<button onClick={() => this.packagePurchaseConfirm(this.state.package_id)} type="button" className="btn btn-primary rounded-pill">{strings["confirm"]}</button>
													}
												</div>
											</>
											:
											<div className="modal-footer mt-2">
												<button onClick={() => this.setState({ is_modal: !this.state.is_modal })} type="button" className="btn btn-warning rounded-pill" style={{ background: "#DE9E5E", marginRight: 10 }}>{strings["Cancel"]}</button>
												{
													this.state.is_usdt_approved ?
														<>
															{this.state.isLoading ?
																<div type="button" className="btn btn-primary loader-btn rounded-pill">
																	<div class="spinner-border loader-spinner" role="status">
																		<span class="visually-hidden">{strings["Loading"]}...</span>
																	</div>
																	{strings["Loading"]}
																</div>
																:
																<button onClick={() => this.doApproveUSDT()} type="button" className="btn btn-primary rounded-pill" style={{ marginRight: 10 }}>{strings["Approve USDT"]}</button>
															}
														</>
														:
														null
												}
												{
													this.state.is_fud_approved ?
														<>
															{this.state.isLoading ?
																<div type="button" className="btn btn-primary loader-btn rounded-pill">
																	<div class="spinner-border loader-spinner" role="status">
																		<span class="visually-hidden">{strings["Loading"]}...</span>
																	</div>
																	{strings["Loading"]}
																</div>
																:
																<button onClick={() => this.doApproveFUD(this.state.package_id)} type="button" className="btn btn-primary rounded-pill" style={{ marginRight: 10 }}>{strings["Approve FUT"]}</button>
															}
														</>
														:
														null
												}

												{this.state.isLoading ?
													<div type="button" className="btn btn-primary loader-btn rounded-pill">
														<div class="spinner-border loader-spinner" role="status">
															<span class="visually-hidden">{strings["Loading"]}...</span>
														</div>
														{strings["Loading"]}
													</div>
													:
													<button onClick={() => this.doPurchase()} type="button" className="btn btn-primary rounded-pill">{strings["confirm"]}</button>
												}
											</div>
									}

								</div>
							</Modal>

						</div>
					</div>

				</div >
				<SideBarMobile />
			</>
		);
	}
}

export default NodesScreen;
