import React from "react";
import { NavLink } from "react-router-dom";
import strings from "../Language";
class SideBarWeb extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: localStorage.getItem("language"),
            menu_show: false,
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
    }

    /**
     * Change Language
     * @param {string} obj 
     */
    handleChange = async (event) => {
        let obj = event.target.value;
        if (obj === "th") {
            strings.setLanguage('th');
            await localStorage.setItem("language", 'th');
            this.setState({ lang: 'th' });
            window.location.reload();
        }
        else {
            strings.setLanguage('en');
            await localStorage.setItem("language", 'en');
            this.setState({ lang: 'en' });
            window.location.reload();
        }
    };

    render() {
        return (
            <>
                <div className="col-md-3 sidebar">
                    <figure>
                        <img src="assets/images/logo.png" alt="" />
                    </figure>
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <NavLink to={"/dashboard"} activeclassname="active" className="nav-link" >{strings["Dashboard"]}</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={"/nodes"} activeclassname="active" className="nav-link" >{strings["NODES"]}</NavLink>
                        </li>
                        {
                            /*
                                <li className="nav-item">
                                    <NavLink to={"/swap"} activeclassname="active" className="nav-link" >{strings["SWAP"]}</NavLink>
                                </li>
                            */
                        }
                        <li className="nav-item">
                            <NavLink to={"/friend-list"} activeclassname="active" className="nav-link" >{strings["FRIEND LIST"]}</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={"/p2p-listing"} activeclassname="active" className="nav-link" >{strings["P2P LISTING"]}🔒</NavLink>
                        </li>
                    </ul>
                </div>
            </>
        );
    }
}

export default SideBarWeb;