import React from "react";
import { NavLink } from "react-router-dom";
import strings from "../Language";

class SideBarMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: localStorage.getItem("language"),
            menu_show: false,
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
    }

    /**
     * Change Language
     * @param {string} obj 
     */
    handleChange = async (event) => {
        let obj = event.target.value;
        if (obj === "th") {
            strings.setLanguage('th');
            await localStorage.setItem("language", 'th');
            this.setState({ lang: 'th' });
            window.location.reload();
        }
        else {
            strings.setLanguage('en');
            await localStorage.setItem("language", 'en');
            this.setState({ lang: 'en' });
            window.location.reload();
        }
    };

    render() {
        return (
            <>
                <nav className="navigation fixed-bottom ">
                    <div className="container">
                        <div className="row align-items-center w-100">
                            <div className="col">
                                <NavLink to={"/dashboard"} activeclassname="active">
                                    <picture>
                                        <img src="assets/images/icn_dashboard.svg" className="filter-green" alt="" />
                                    </picture><br />
                                    {strings["Dashboard"]}
                                </NavLink>
                            </div>
                            <div className="col">
                                <NavLink to={"/nodes"} activeclassname="active">
                                    <picture>
                                        <img src="assets/images/icn_cube.svg" alt="" />
                                    </picture><br />
                                    {strings["NODES"]}
                                </NavLink>
                            </div>
                            {
                                /*<div className="col">
                                    <NavLink to={"/swap"} activeclassname="active">
                                        <picture>
                                            <img src="assets/images/icn_swap.svg" alt="" />
                                        </picture><br />
                                        {strings["SWAP"]}
                                    </NavLink>
                                 </div>
                                */
                            }
                            <div className="col">
                                <NavLink to={"/friend-list"} activeclassname="active">
                                    <picture>
                                        <img src="assets/images/icn_friend.svg" alt="" />
                                    </picture><br />
                                    {strings["FRIEND LIST"]}
                                </NavLink>
                            </div>
                            <div className="col">
                                <NavLink to={"/p2p-listing"} activeclassname="active">
                                    <picture>
                                        <img src="assets/images/icn_list.svg" alt="" />
                                    </picture><br />
                                    {strings["P2P LISTING"]}🔒
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </nav>
            </>
        );
    }
}

export default SideBarMobile;